import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import messageListenerService from './services/messages/listeners'
import * as Sentry from '@sentry/vue'
import VueProgressBar from 'vue-progressbar'
import { registerGlobalComponents } from './helpers/registerGlobalComponents'
import { StripePlugin } from '@vue-stripe/vue-stripe'

import './assets/css/Global.css'

//setup message listening service
messageListenerService.addListners()

//setup Stripe
Vue.use(StripePlugin, {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  testMode: process.env.VUE_APP_STRIPE_TEST_MODE || false,
})

//setup VueProgressBar
const progressBarOptions = {
  color: '#72CDE0',
  failedColor: '#ff5252',
  thickness: '3px',
}
Vue.use(VueProgressBar, progressBarOptions)

//setup Sentry
Sentry.init({
  Vue: Vue,
  environment: process.env.VUE_APP_SENTRY_ENV,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [process.env.VUE_APP_SYMBO_UI_URL, /^\//],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        process.env.VUE_APP_SYMBO_API_URL,
        process.env.VUE_APP_SYMBO_ANALYTICS_URL,
      ],
    }),
  ],
  tracingOptions: {
    trackComponents: true,
  },
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'localhost',
    process.env.VUE_APP_SYMBO_API_URL,
    // process.env.VUE_APP_SYMBO_ANALYTICS_URL,
  ],
  logErrors: true,
  attachProps: true,
  attachStacktrace: true,
  maxBreadCrumbs: 5,
  debug: false,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
})

// import global components
registerGlobalComponents(Vue)

Vue.config.productionTip = false

// v-visible custom directive
Vue.directive('visible', (el, binding) => {
  el.style.visibility = binding.value ? 'visible' : 'hidden'
})

// Vue.config.errorHandler = function (err, vm, info) {
//   console.error(`Error: ${err.toString()}\nInfo: ${info}`)
//   return true
// }

Vue.config.warnHandler = function (msg, vm, trace) {
  console.warn(`Warning: ${msg}\nTrace: ${trace}`)
  return true
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
